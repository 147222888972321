import React from 'react'
import './content-header.css'

interface ContentHeaderProps {
  title: string
  subTitle?: string
}

const ContentHeader: React.FC<ContentHeaderProps> = (props: ContentHeaderProps) => {
  return (
    <div className='content-header-container'>
      <h2 className='content-header-title'>{props.title}</h2>
      <div className='content-header-subtitle'>{props.subTitle}</div>
    </div>
  )
}

export default ContentHeader
