import { IOutfitterRepository } from '../../../interfaces/gateways/outfitter.repository'

export default class SetOutfitterPageCase {
  constructor (
    private readonly outfitterRepository: IOutfitterRepository
  ) {
  }

  execute (page: number): void {
    this.outfitterRepository.setOutfitterPage(page)
  }
}
