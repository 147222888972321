import React from 'react'
import './header-nav.css'
import { MAIN_SITE_URL } from '../../../config'

const HeaderNav: React.FC = () => (
  <ul className='nav justify-content-center header-navigation-bottom'>
    <li className='nav-item'>
      <a className='nav-link' href='/'>Home</a>
    </li>
    <li className='nav-item'>
      <a className='nav-link' href={`${MAIN_SITE_URL}`}>Organization</a>
    </li>
    <li className='nav-item'>
      <a className='nav-link' href='/search'>Animals</a>
    </li>
    <li className='nav-item'>
      <a className='nav-link' href='/outfitters'>Outfitters</a>
    </li>
    <li className='nav-item'>
      <a className='nav-link' href={`${MAIN_SITE_URL}/shop`}>Shop</a>
    </li>
  </ul>
)

export default HeaderNav
