import React, { useContext } from 'react'
import { StoreContext } from '../../../entities'
import { Observer } from 'mobx-react-lite'
import HomepageHero from './homepage-hero.component'
import SearchController from '../../../interfaces/controllers/search.controller'
import SearchPresenter from '../../../interfaces/presenters/search.presenter'
import { useHistory } from 'react-router-dom'

const HomepageHeroContainer: React.FC<any> = () => {
  const store = useContext(StoreContext)
  const controller = new SearchController(store)
  const presenter = new SearchPresenter(store)
  const history = useHistory()

  const handleSubmit = async (): Promise<void> => {
    await controller.search()
    history.push('/search')
  }

  return (
    <Observer>
      {() => (
        <>
          <HomepageHero
            changeInput={(value) => {
              controller.setSearch(value)
            }}
            searchInput={presenter.getSearchTerm()}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </Observer>
  )
}

export default HomepageHeroContainer
