import { IStore } from '../../entities'
import OutfitterEntity from '../../entities/outfitter.entity'

export interface IOutfitterRepository {
  setOutfitterResults: (outfitters: OutfitterEntity[]) => void
  getOutfitterResults: () => OutfitterEntity[]
  setSearchTerm: (term: string) => void
  getSearchTerm: () => string
  getPage: () => number
  addOutfitter: (outfitter: OutfitterEntity) => void
  getOutfitter: (slug: string) => OutfitterEntity | undefined
  setFeaturedOutfitters: (outfitters: OutfitterEntity[]) => void
  getFeaturedOutfitters: () => OutfitterEntity[]
  setOutfitterPage: (page: number) => void
  setOutfitterTotal: (total: number) => void
  setLoading: (isLoading: boolean) => void
}

export default class OutfitterRepository implements IOutfitterRepository {
  private readonly store: IStore

  constructor (store: IStore) {
    this.store = store
  }

  getOutfitterResults (): OutfitterEntity[] {
    return this.store.outfitters.results
  }

  setOutfitterResults (outfitters: OutfitterEntity[]): void {
    this.store.outfitters.results = outfitters
  }

  setSearchTerm (term: string): void {
    this.store.outfitters.searchTerm = term
  }

  getSearchTerm (): string {
    return this.store.outfitters.searchTerm
  }

  getPage (): number {
    return this.store.outfitters.currentPage
  }

  addOutfitter (outfitter: OutfitterEntity): void {
    this.store.outfitters.outfitterMap.set(outfitter.slug, outfitter)
  }

  getOutfitter (slug: string): OutfitterEntity | undefined {
    return this.store.outfitters.outfitterMap.get(slug)
  }

  setFeaturedOutfitters (outfitters: OutfitterEntity[]): void {
    this.store.outfitters.featuredOutfitters = outfitters
  }

  getFeaturedOutfitters (): OutfitterEntity[] {
    return this.store.outfitters.featuredOutfitters
  }

  setOutfitterPage (page: number): void {
    this.store.outfitters.currentPage = page
  }

  setOutfitterTotal (total: number): void {
    this.store.outfitters.totalResults = total
  }

  setLoading (isLoading: boolean): void {
    this.store.outfitters.loading = isLoading
  }
}
