import React from 'react'
import { Observer } from 'mobx-react-lite'
import Header from '../components/header/header.component'
import Footer from '../components/footer/footer.component'
import CategoriesContainer from '../components/category-card/categories.container'
import HomepageHeroContainer from '../components/homepage-hero/homepage-hero.container'
import FeaturedOutfittersContainer from '../components/featured-outfitters/featured-outfitters.container'

interface HomePageScreenProps {
  title?: string
}

const HomePageScreen: React.FC<HomePageScreenProps> = () => (
  <Observer>
    {() => (
      <div>
        <div>
          <Header />
          <HomepageHeroContainer />
          <section>
            <CategoriesContainer />
          </section>
          <FeaturedOutfittersContainer size='large' />
          <Footer />
        </div>
      </div>
    )}
  </Observer>
)

export default HomePageScreen
