import { makeAutoObservable } from 'mobx'
import CategoryEntity from './category.entity'

export default class CategoryStore {
  list: CategoryEntity[] = []

  constructor () {
    makeAutoObservable(this)
  }
}
