import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../entities'
import { Observer } from 'mobx-react-lite'
import ContentHeader from '../content-header/content-header.component'
import SearchPresenter from '../../../interfaces/presenters/search.presenter'
import SearchController from '../../../interfaces/controllers/search.controller'
import SearchResults from './search-results.component'

const SearchResultsContainer: React.FC<any> = () => {
  const store = useContext(StoreContext)
  const presenter = new SearchPresenter(store)
  const controller = new SearchController(store)

  useEffect(() => {
    controller.handlePageChange(0)
    controller.loadAnimals()
  })

  return (
    <>
      <ContentHeader
        title='Categories'
        subTitle='At half-past eight the door opened, the policeman appeared, and, requesting them to follow him, led the way to an adjoining hall. '
      />
      <Observer>
        {() => (
          <SearchResults
            species={presenter.getSpecies()}
            handlePageChange={(page: number) => {
              controller.handlePageChange(page - 1)
              controller.loadAnimals()
            }}
            isLoading={presenter.getLoaded()}
            animals={presenter.getResults()}
            totalPages={presenter.getTotalResults()}
            currentPage={presenter.getCurrentPage()}
          />
        )}
      </Observer>
    </>
  )
}

export default SearchResultsContainer
