import { IStore } from '../../entities'

interface ICategoryViewModel {
  slug: string
  image: string
  name: string
  id: string
}

export default class CategoryPresenter {
  private readonly store: IStore

  constructor (store: IStore) {
    this.store = store
  }

  getCategories (): ICategoryViewModel[] {
    const categories: ICategoryViewModel[] = []
    this.store.categories.list.forEach((cat) => {
      categories.push({
        id: cat.id,
        name: cat.name,
        image: cat.image,
        slug: cat.slug
      })
    })
    return categories
  }
}
