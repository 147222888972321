import React, { useContext, useEffect } from 'react'
import { Observer } from 'mobx-react-lite'
import OutfitterDetails from './outfitter-details.component'
import { useParams } from 'react-router-dom'
import OutfitterController from '../../../interfaces/controllers/outfitter.controller'
import { StoreContext } from '../../../entities'
import OutfitterPresenter from '../../../interfaces/presenters/outfitter.presenter'

interface OutfitterDetailsContainerProps {
  type?: string
}

const OutfitterDetailsContainer: React.FC<OutfitterDetailsContainerProps> = (props: OutfitterDetailsContainerProps) => {
  const store = useContext(StoreContext)
  const controller = new OutfitterController(store)
  const presenter = new OutfitterPresenter(store)

  const params: {slug: string} = useParams()

  useEffect(() => {
    controller.loadOutfitter(params.slug)
  })

  return (
    <Observer>
      {() => {
        const outfitter = presenter.getOutfitter(params.slug)
        return (
          <OutfitterDetails
            id={outfitter.id}
            slug={outfitter.slug}
            title={outfitter.title}
            details={outfitter.details}
            species={outfitter.species}
            contacts={outfitter.contacts}
            location={outfitter.location}
            phone={outfitter.phone}
            email={outfitter.email}
            website={outfitter.website}
            image={outfitter.image}
          />
        )
      }}
    </Observer>
  )
}

export default OutfitterDetailsContainer
