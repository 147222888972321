import React from 'react'
import './search-card.css'

interface SearchCardProps {
  title: string
  slug: string
  image?: any
  alt?: string
}

const SearchCard: React.FC<SearchCardProps> = (props: SearchCardProps) => {
  return (
    <div className='search-card-container'>
      <a href={`/animal/${props.slug}`}>
        <img className='search-card-image' src={props.image} alt={`${props.title} result`} />
        <div className='search-card-title'>{props.title}</div>
      </a>
    </div>
  )
}

export default SearchCard
