import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../entities'
import { Observer } from 'mobx-react-lite'
import SingleAnimal from './single-animal.component'
import AnimalPresenter from '../../../interfaces/presenters/animal.presenter'
import AnimalController from '../../../interfaces/controllers/animal.controller'
import { useParams } from 'react-router-dom'

const SingleAnimalContainer: React.FC<any> = () => {
  const store = useContext(StoreContext)
  const presenter = new AnimalPresenter(store)
  const controller = new AnimalController(store)
  const params: {slug: string} = useParams()

  useEffect(() => {
    controller.loadAnimal(params.slug)
  })

  return (
    <Observer>
      {() => {
        const viewModel = presenter.getAnimal(params.slug)
        return (
          <SingleAnimal
            animalId={viewModel.animalId}
            animalName={viewModel.animalName}
            location={viewModel.location}
            scientificName={viewModel.scientificName}
            isSingleImage={viewModel.isSingleImage}
            locationDescription={viewModel.locationDescription}
            animalSummary={viewModel.animalSummary}
            images={viewModel.images}
            locationImage={viewModel.locationImage}
          />
        )
      }}
    </Observer>
  )
}

export default SingleAnimalContainer
