import { IStore } from '../../entities'
import CategoryEntity from '../../entities/category.entity'

export interface ICategoryRepository {
  clear: () => void
  addCategories: (categories: CategoryEntity[]) => void
}

export default class CategoriesRepository implements ICategoryRepository {
  private readonly store: IStore

  constructor (store: IStore) {
    this.store = store
  }

  addCategories (categories: CategoryEntity[]): void {
    this.store.categories.list = categories
  }

  clear (): void {
    this.store.categories.list = []
  }
}
