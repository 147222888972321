import React from 'react'
import './App.css'
import getStore, { StoreContext } from '../../../entities'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import HomePageScreen from '../../screens/home-page.screen'
import SearchScreen from '../../screens/search.screen'
import SingleAnimalScreen from '../../screens/single-animal.screen'
import OutfittersScreen from '../../screens/outfitter-results.screen'
import OutfitterSingleScreen from '../../screens/outfitter-single.screen'

function App (): any {
  return (
    <StoreContext.Provider value={getStore()}>
      <div className='App'>
        <Router>
          <div>
            <Switch>
              <Route path='/search'>
                <SearchScreen />
              </Route>
              <Route path='/outfitters/:slug'>
                <OutfitterSingleScreen />
              </Route>
              <Route path='/outfitters'>
                <OutfittersScreen />
              </Route>
              <Route path='/users'>
                <div>These are details</div>
              </Route>
              <Route path='/animal/:slug'>
                <SingleAnimalScreen />
              </Route>
              <Route path='/'>
                <HomePageScreen />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </StoreContext.Provider>
  )
}

export default App
