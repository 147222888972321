import ViewCategoriesCase from '../../usecases/categories/view-categories/view-categories.case'
import { IStore } from '../../entities'
import CategoriesRepository from '../gateways/CategoriesRepository'
import ContentfulGateway from '../gateways/ContentfulGateway'

export default class CategoryController {
  private readonly viewCategoriesUseCase: ViewCategoriesCase

  constructor (store: IStore) {
    const categoryRepo = new CategoriesRepository(store)
    const contentfulGateway = new ContentfulGateway()
    this.viewCategoriesUseCase = new ViewCategoriesCase(categoryRepo, contentfulGateway)
  }

  async loadCategories (): Promise<null> {
    return this.viewCategoriesUseCase.execute()
  }
}
