const contentful = require('contentful')

export const PAGINATION_COUNT = 12
const client = contentful.createClient({
  space: '68ogdvplcwob',
  accessToken: '2wYqUJe0dkao9sbfq65s00BtgV6f5-G0wLCBbZE1xpE'
})

export interface IContentfulGateway {
  getAnimals: (page: number) => Promise<IContentfulCollection<IContentfulAnimal>>
  getAnimal: (slug: string) => Promise<IContentfulAnimal | undefined>
  searchAnimals: (query: string, page: number, params?: any) => Promise<IContentfulCollection<IContentfulAnimal>>
  getCategories: () => Promise<IContentfulCategory[]>
  getAllAds: () => Promise<IContentfulCollection<IContentfulAd>>
  searchOutfitters: (query: string, page: number, animalId?: string) => Promise<IContentfulCollection<IContentfulOutfitter>>
  getOutfitter: (slug: string) => Promise<IContentfulOutfitter | undefined>
  getFeaturedOutfitters: () => Promise<IContentfulCollection<IContentfulOutfitter>>
}

export default class ContentfulGateway implements IContentfulGateway {
  async getCategories (): Promise<IContentfulCategory[]> {
    const results = await client.getEntries({ content_type: 'category' })
    return results.items
  }

  async getAnimals (page: number = 0): Promise<IContentfulCollection<IContentfulAnimal>> {
    return client.getEntries({ content_type: 'animal', skip: page * PAGINATION_COUNT, limit: PAGINATION_COUNT })
  }

  extractArrayParameterString (categoryObject?: string[]): string | undefined {
    let parameters: undefined | string
    if (categoryObject !== null && categoryObject !== undefined) {
      const filteredCategories = categoryObject.filter((cat) => cat !== null)
      if (filteredCategories.length > 0) {
        parameters = categoryObject.toString()
      }
    }
    return parameters
  }

  async searchAnimals (query: string, page: number, params?: {
    categories?: string[]
    continents?: string[]
    startWeight?: number
    endWeight?: number
  }): Promise<IContentfulCollection<IContentfulAnimal>> {
    const categories = this.extractArrayParameterString(params?.categories)
    const continents = this.extractArrayParameterString(params?.continents)
    const order = this.getOrderByField(query, categories, continents)

    return client.getEntries({
      'fields.name[match]': query,
      'fields.category.fields.slug[in]': categories,
      'fields.category.sys.contentType.sys.id': 'category',
      'fields.continent.fields.slug[in]': continents,
      'fields.continent.sys.contentType.sys.id': 'continent',
      content_type: 'animal',
      skip: page * PAGINATION_COUNT,
      limit: PAGINATION_COUNT,
      order: order
    })
  }

  getOrderByField (query: string, categories: string | undefined, continents: string | undefined): string | undefined {
    let order: string | undefined = 'fields.name'
    if (query.length === 0 && categories === undefined && continents === undefined) {
      if (categories === 'north-american-big-game') {
        order = 'fields.order'
      } else {
        order = 'fields.name'
      }
    }
    return order
  }

  async searchOutfitters (query: string, page: number, animalId?: string): Promise<IContentfulCollection<IContentfulOutfitter>> {
    return client.getEntries({
      'fields.name[match]': query,
      content_type: 'outfitter',
      links_to_entry: animalId,
      skip: page * PAGINATION_COUNT,
      limit: PAGINATION_COUNT
    })
  }

  async getAnimal (slug: string): Promise<IContentfulAnimal | undefined> {
    return this.getSingleEntityFromSlug(slug, 'animal')
  }

  async getAllAds (): Promise<IContentfulCollection<IContentfulAd>> {
    return client.getEntries({
      content_type: 'ad'
    })
  }

  async getOutfitter (slug: string): Promise<IContentfulOutfitter | undefined> {
    return this.getSingleEntityFromSlug(slug, 'outfitter')
  }

  async getSingleEntityFromSlug<T> (slug: string, type: 'outfitter' | 'animal'): Promise<T | undefined> {
    const results = await client.getEntries({
      'fields.slug': slug,
      content_type: type
    })
    if (results.items.length > 0) {
      return results.items[0]
    } else {
      return undefined
    }
  }

  async getFeaturedOutfitters (): Promise<IContentfulCollection<IContentfulOutfitter>> {
    return client.getEntries({ content_type: 'outfitter', 'fields.featured': true })
  }
}

export interface IContentfulCollection<T> {
  limit: number
  skip: number
  total: number
  items: T[]
}

export interface IContentfulModel {
  sys: {
    id: string
  }
}

export interface IContentfulImage {
  fields: {
    file: {
      url: string
    }
  }
}

export interface IContentfulAnimal extends IContentfulModel{
  fields: {
    behavior: string
    slug: string
    category: IContentfulCategory
    location: string
    locationDescription: string
    name: string
    scientificName: string
    summary: string
    images: IContentfulImage[]
    mapImage: IContentfulImage
  }
}

export interface IContentfulOutfitter extends IContentfulModel{
  fields: {
    name: string
    image: IContentfulImage
    contact: string
    location: string
    phoneNumber: string[]
    fax: string
    slug: string
    email: string
    website: string
    description: string
    species: IContentfulAnimal[]
  }
}

export interface IContentfulCategory extends IContentfulModel{
  fields: {
    title: string
    slug: string
    image: IContentfulImage
  }
}

export interface IContentfulAd extends IContentfulModel {
  fields: {
    name: string
    image: IContentfulImage
    size: string
    url: string
  }
}
