import { IContentfulGateway } from '../../../interfaces/gateways/ContentfulGateway'
import { IOutfitterRepository } from '../../../interfaces/gateways/outfitter.repository'
import OutfitterEntity from '../../../entities/outfitter.entity'
import { contentfulOutfitterToOutfitterEntity } from '../../../entities/transformers'

export default class LoadFeaturedOutfittersCase {
  constructor (
    private readonly contentfulGateway: IContentfulGateway,
    private readonly outfitterRepository: IOutfitterRepository
  ) {
  }

  async execute (): Promise<void> {
    const outfittersAPIResult = await this.contentfulGateway.getFeaturedOutfitters()
    const outfitters: OutfitterEntity[] = []
    outfittersAPIResult.items.forEach((outfitterAPIEntity) => {
      outfitters.push(contentfulOutfitterToOutfitterEntity(outfitterAPIEntity))
    })
    this.outfitterRepository.setFeaturedOutfitters(outfitters)
  }
}
