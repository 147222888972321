import React from 'react'
import SearchCard from '../search-card/search-card.component'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './search-results.css'
import AdContainer from '../ad/ad.container'
import { PAGINATION_COUNT } from '../../../interfaces/gateways/ContentfulGateway'
interface SearchResultsProps {
  totalPages: number
  currentPage: number
  isLoading?: boolean
  handlePageChange: (page: number) => void
  species: string
  animals: Array<{
    id: string
    slug: string
    name: string
    image: string
  }>
}

const SearchResults: React.FC<SearchResultsProps> = (props: SearchResultsProps) => {
  const handlePageChange = (page: number): void => {
    props.handlePageChange(page)
  }

  return (
    <div className='container search-results-container'>
      <h3 className='search-result-title'>Species</h3>
      <p style={{ textTransform: 'capitalize' }}>{props.species}</p>
      <div className='row'>
        {props.isLoading === true
          ? <SearchLoadingView />
          : props.animals.map((animal, index) => (
            <div key={`${index}`} className='col-md-4'>
              <SearchCard slug={animal.slug} image={animal.image} title={animal.name} />
            </div>
          ))}
      </div>

      <AdContainer type='horizontal-small' />
      <Pagination
        pageSize={PAGINATION_COUNT}
        onChange={handlePageChange}
        showTotal={(total, range) =>
          `${range[0]} - ${range[1]} of ${total} items`}
        total={props.totalPages}
        current={props.currentPage}
      />

    </div>
  )
}

const emptyItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const SearchLoadingView = (): any => (
  <>
    {emptyItems.map((item) => (
      <div key={`${item}`} className='col-md-4'>
        <div className='search-loading-container' />
      </div>
    ))}
  </>
)

export default SearchResults
