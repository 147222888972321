import React from 'react'
import './category-card.css'
import SlamButton from '../button/slam-button.component'

interface CategoryCardProps {
  image: any
  alt?: string
  title?: string
  onClick?: () => void
}

const CategoryCard: React.FC<CategoryCardProps> = (props: CategoryCardProps) => {
  return (
    <div className='category-card-container'>
      <img className='category-card-image' src={props.image} alt={props.alt} />
      <div className='category-card-title'>{props.title}</div>
      <div style={{ textAlign: 'center' }}>
        <SlamButton onClick={props.onClick}>View</SlamButton>
      </div>
    </div>
  )
}

export default CategoryCard
