import React from 'react'
import './search-filter.css'
import SlamButton from '../button/slam-button.component'
import { Accordion, Form } from 'react-bootstrap'
import FormComponent from '../form/form.component'

interface SearchFilterProps {
  searchTerm: string
  categories: string[]
  continents: string[]
  onSearch: () => void
  onSearchTextUpdate: (text: string) => void
  onToggleCategory: (category: string) => void
  onToggleContinent: (continent: string) => void
}

const SearchFilter: React.FC<SearchFilterProps> = (props: SearchFilterProps) => {
  return (
    <div className='search-filter-container'>
      <h3 className='search-filter-sidebar-title'>Filter</h3>
      <FormComponent onSearch={props.onSearch}>
        <div className='input-group mb-3'>
          <input
            type='text'
            className='form-control hero-search-input'
            placeholder='Search for species'
            aria-label='Search for species'
            aria-describedby='basic-addon2'
            value={props.searchTerm}
            onChange={(e) => {
              props.onSearchTextUpdate(e.currentTarget.value)
            }}
          />
          <div className='input-group-append'>
            <SlamButton type='submit' color='yellow'>Search</SlamButton>
          </div>
        </div>
      </FormComponent>
      <Accordion className='filter-accordion' defaultActiveKey='0'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header className='filter-accord-header'>Filter By Category</Accordion.Header>
          <Accordion.Body>
            <Form>
              {categories.map((category) => (
                <Form.Check
                  key={category.slug}
                  type='checkbox'
                  id={category.id}
                  label={category.label}
                  value={category.id}
                  onChange={() => { props.onToggleCategory(category.slug) }}
                  checked={props.categories.includes(category.slug)}
                />
              ))}replaceAll

            </Form>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header className='filter-accord-header'>Filter By Content</Accordion.Header>
          <Accordion.Body>
            {continents.map((continent) => (
              <Form.Check
                type='checkbox'
                key={continent.slug}
                id={continent.id}
                label={continent.label}
                onChange={() => { props.onToggleContinent(continent.slug) }}
                checked={props.continents.includes(continent.slug)}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

    </div>
  )
}

export default SearchFilter

const categories = [{
  id: '1',
  label: 'North American Sheep',
  slug: 'north-american-sheep'
}, {
  id: '2',
  label: 'International Ovis',
  slug: 'international-ovis'
}, {
  id: '3',
  label: 'International Carpa',
  slug: 'international-carpa'
}, {
  id: '4',
  label: 'North American Big Game',
  slug: 'north-american-big-game'
}]

const continents = [{
  id: 'c1',
  label: 'North America',
  slug: 'north-america'
}, {
  id: 'c2',
  label: 'South America',
  slug: 'south-america'
}, {
  id: 'c3',
  label: 'Antarctica',
  slug: 'antarctica'
}, {
  id: 'c4',
  label: 'Europe',
  slug: 'europe'
}, {
  id: 'c5',
  label: 'Asia',
  slug: 'asia'
}, {
  id: 'c6',
  label: 'Africa',
  slug: 'africa'
}, {
  id: 'c7',
  label: 'Australia',
  slug: 'australia'
}]
