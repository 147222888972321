import { makeAutoObservable } from 'mobx'
import AnimalEntity from './animal.entity'

export default class AnimalStore {
  loading: boolean = false
  results: AnimalEntity[] = []
  totalResults: number = 0
  currentPage: number = 0
  searchTerm: string = ''
  animalMap: Map<string, AnimalEntity> = new Map()
  searchParams: {
    categories: string[]
    continents: string[]
    startWeight: number
    endWeight: number
  } = {
    categories: [],
    continents: [],
    startWeight: 0,
    endWeight: 150000
  }

  constructor () {
    makeAutoObservable(this)
  }
}
