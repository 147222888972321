import { IContentfulGateway } from '../../../interfaces/gateways/ContentfulGateway'
import { IAnimalRepository } from '../../../interfaces/gateways/animal.repository'
import { contentfulAnimalsToAnimalEntities } from '../../../entities/transformers'

export default class SearchAnimalsByNameCase {
  constructor (
    public contentfulGateway: IContentfulGateway,
    public animalRepository: IAnimalRepository
  ) {
  }

  async execute (): Promise<void> {
    const page = this.animalRepository.getPage()
    const params = this.animalRepository.getSearchParams()
    const searchTerm = this.animalRepository.getSearchTerm()
    this.animalRepository.setLoading(true)
    const animalResults = await this.contentfulGateway.searchAnimals(searchTerm, page, params)
    const animalEntities = contentfulAnimalsToAnimalEntities(animalResults.items)
    this.animalRepository.setTotal(animalResults.total)
    this.animalRepository.addAnimalsToResults(animalEntities)
    this.animalRepository.setLoading(false)
  }
}
