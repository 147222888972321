import AnimalEntity from '../../../entities/animal.entity'
import { IAnimalRepository } from '../../../interfaces/gateways/animal.repository'

export default class ViewSingleAnimal {
  constructor (
    private readonly animalRepository: IAnimalRepository
  ) {
  }

  execute (slug: string): AnimalEntity | undefined {
    return this.animalRepository.getAnimal(slug)
  }
}
