import React, { useContext } from 'react'
import { StoreContext } from '../../../entities'
import { Observer } from 'mobx-react-lite'
import OutfitterFilter from './outfitter-filter.component'
import OutfitterController from '../../../interfaces/controllers/outfitter.controller'
import OutfitterPresenter from '../../../interfaces/presenters/outfitter.presenter'

const OutfitterFilterContainer: React.FC<any> = () => {
  const store = useContext(StoreContext)
  const presenter = new OutfitterPresenter(store)
  const controller = new OutfitterController(store)

  const handleSearch = async (): Promise<void> => {
    await controller.search()
  }

  const handleSearchInput = (text: string): void => {
    controller.setSearch(text)
  }

  return (
    <Observer>
      {() => (
        <>
          <OutfitterFilter
            searchTerm={presenter.getSearchTerm()}
            onSearch={handleSearch}
            onSearchTextUpdate={handleSearchInput}
          />
          <div style={{ display: 'none' }}>{presenter.getSearchTerm()}</div>
        </>
      )}
    </Observer>
  )
}

export default OutfitterFilterContainer
