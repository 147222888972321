import React from 'react'
import Header from '../components/header/header.component'
import Footer from '../components/footer/footer.component'
import AdContainer from '../components/ad/ad.container'
import OutfitterResultsContainer from '../components/outfitter-results/outfitter-results.container'
import OutfitterFilterContainer from '../components/outfitter-filter/outfitter-filter.container'
import FeaturedOutfittersContainer from '../components/featured-outfitters/featured-outfitters.container'

interface OutfitterResultsScreenProps {
  title?: string
}

const OutfittersScreen: React.FC<OutfitterResultsScreenProps> = () => {
  return (
    <div>
      <Header />
      <section />
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <OutfitterFilterContainer />
            <AdContainer type='vertical' />
            <AdContainer type='square' />
          </div>
          <div className='col-md-8'>
            <OutfitterResultsContainer />
          </div>
        </div>
      </div>
      <FeaturedOutfittersContainer size='small' />
      <Footer />
    </div>
  )
}

export default OutfittersScreen
