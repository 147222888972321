import { makeAutoObservable } from 'mobx'

export default class CategoryEntity {
  id: string = ''
  name: string = ''
  image: string =''
  slug: string = ''

  constructor () {
    makeAutoObservable(this)
  }
}
