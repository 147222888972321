import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../entities'
import { Observer } from 'mobx-react-lite'
import FeaturedOutfitters from './featured-outfitters.component'
import OutfitterPresenter from '../../../interfaces/presenters/outfitter.presenter'
import OutfitterController from '../../../interfaces/controllers/outfitter.controller'

interface FeaturedOutfittersContainerProps {
  size: 'large' | 'small'
}
const FeaturedOutfittersContainer: React.FC<FeaturedOutfittersContainerProps> = (props: FeaturedOutfittersContainerProps) => {
  const store = useContext(StoreContext)
  const presenter = new OutfitterPresenter(store)
  const controller = new OutfitterController(store)

  useEffect(() => {
    controller.loadFeaturedOutfitters()
  })

  return (
    <Observer>
      {() => {
        const outfitters = presenter.getFeaturedOutfitters()
        return (
          <FeaturedOutfitters size={props.size} outfitters={outfitters} />
        )
      }}
    </Observer>
  )
}

export default FeaturedOutfittersContainer
