import { IStore } from '../../entities'
import ViewSingleAdCase from '../../usecases/ads/view-single-ad/view-single-ad.case'
import AdRepository from '../gateways/ad.repository'

export interface IAdViewModel {
  image: string
  url: string
}

export default class AdPresenter {
  private readonly store: IStore
  private readonly viewSingleAdCase: ViewSingleAdCase

  constructor (store: IStore) {
    this.store = store
    this.viewSingleAdCase = new ViewSingleAdCase(new AdRepository(store))
  }

  getAd (type: 'horizontal-small' | 'horizontal-large' | 'vertical' | 'square'): IAdViewModel {
    const ad = this.viewSingleAdCase.execute(type)
    if (ad !== undefined) {
      return {
        image: ad.image,
        url: ad.url
      }
    } else {
      return {
        image: '',
        url: ''
      }
    }
  }
}
