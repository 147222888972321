import React from 'react'
import './outfitter-details.css'

interface OutfitterDetailsProps {
  id: string
  slug: string
  title: string
  details: string
  species: Array<{name: string, slug: string}>
  image?: any
  contacts: string
  location: string
  phone: string
  email: string
  website: string
}

const OutfitterDetails: React.FC<OutfitterDetailsProps> = (props: OutfitterDetailsProps) => {
  return (
    <div className='outfitter-details-container'>
      <div className='row'>
        <div className='col-md-8'>
          <h1 className='outfitter-details-title'>{props.title}</h1>
          <div className='outfitter-details-description'>{props.details}</div>
          <div className='outfitter-species-container'>
            <p />
            <p><b>Species</b></p>
            {props.species.map((species) => (
              <span key={species.slug}><a className='outfitter-species-link' href={`/animal/${species.slug}`}>{species.name}</a> </span>
            ))}
          </div>
        </div>
        <div className='col-md-4'>
          <div className='outfitter-details-right-container'>
            <img className='outfitter-details-image' alt={props.title} src={props.image} />
            <div className='outfitter-details-stats'>
              <p><b>Contacts:</b> {props.contacts}</p>
              <p><b>Location:</b> {props.location}</p>
              <p><b>Phone:</b> {props.phone}</p>
              <p><b>Email:</b> {props.email}</p>
              <p><b>Website:</b> <a target='_blank' href={props.website} rel='noreferrer'>{props.website}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OutfitterDetails
