import React from 'react'
import './footer.css'
import { MAIN_SITE_URL } from '../../../config'

const Footer: React.FC = () => {
  return (
    <div className='footer-container'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'>
            <img
              className='footer-logo img-fluid'
              src='https://cdn.b12.io/client_media/ad79D2TZ/a60b5bf0-8b43-11eb-934d-0242ac110003-jpg-regular_image.jpeg'
              alt='slamquestLogo'
            />
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
            <div className='sb-footer__contact'>
              <div className='h3 sb-footer-simple-newsletter__title'>Reach out</div>
              <ul className='sb-footer__list'>
                <li>
                  <a
                    aria-label='Email us at gsco@wildsheep.org'
                    className='email'
                    data-component-field='contact'
                    data-sb-field='email' data-sb-is-link=''
                    data-sb-type='email'
                    href='mailto:gsco@wildsheep.org'
                  >
                    gsco@wildsheep.org
                  </a>
                </li>
                <li>Phone:
                  <a
                    aria-label='Call us at (205) 674-0101'
                    className='tel'
                    href='tel:(205) 674-0101'
                  >
                    (205) 674-0101
                  </a>
                </li>
                <li>Fax:
                  <a
                    aria-label='Fax us at (205) 674-0190'
                    className='tel'
                    href='tel:(205) 674-0190'
                  >
                    (205) 674-0190
                  </a>
                </li>
              </ul>
              <ul className='sb-footer__list social-icons'>
                <li>
                  <a
                    aria-label='Facebook'
                    href='https://www.facebook.com/profile.php?id=10005758228261459725'
                    rel='noreferrer' target='_blank'
                  >
                    <span className='sb-icon'>
                      <i aria-hidden='true' className='fa fa-facebook' />
                    </span><span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    aria-label='Instagram'
                    href='https://www.instagram.com/grandslamclubovis'
                    rel='noreferrer' target='_blank'
                  >
                    <span className='sb-icon'>
                      <i aria-hidden='true' className='fa fa-instagram' />
                    </span><span>Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    aria-label='YouTube'
                    href='https://www.youtube.com/channel/UCjWsIxHfarrcuLCOmy7oYbQ'
                    rel='noreferrer' target='_blank'
                  >
                    <span className='sb-icon'>
                      <i aria-hidden='true' className='fa fa-youtube-play' />
                    </span><span>YouTube</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-5 col-md-5 col-sm-12 col-xs-12'>
            <div className='sb-footer__form'>
              <div
                className='h3 sb-footer-simple-newsletter__title'
              >Sign-up for GSCO News
              </div>

              <div className='newsletter-signup-form__col-btn'>
                <button className='sb-button sb-button--form newsletter-signup-form__btn' type='submit'>
                  <a href={`${MAIN_SITE_URL}`} style={{ color: 'black' }} className='sb-button__text -default'>SIGN UP</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
