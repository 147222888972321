import React from 'react'

interface SlamButtonProps {
  color?: 'yellow' | 'blue'
  onClick?: () => void
  children?: any
  style?: any
  type?: 'button' | 'submit' | 'reset'
}

const SlamButton: React.FC<SlamButtonProps> = (props: SlamButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      className='btn'
      style={BUTTON_STYLE(props)} type={(props.type != null) ? props.type : 'button'}
    >{props.children}
    </button>
  )
}

export default SlamButton

export const BUTTON_STYLE = (props: any): any => {
  return {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '3.15px',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '16px',
    paddingBottom: '16px',
    backgroundColor: props.color === 'yellow' ? '#F3A947' : '#0C2F73',
    color: props.color === 'yellow' ? '#0E2142' : '#FFF',
    ...props.style
  }
}
