import { IContentfulGateway } from '../../../interfaces/gateways/ContentfulGateway'
import { IOutfitterRepository } from '../../../interfaces/gateways/outfitter.repository'
import { contentfulOutfitterToOutfitterEntity } from '../../../entities/transformers'

export default class LoadSingleOutfitterCase {
  constructor (
    private readonly contentfulGateway: IContentfulGateway,
    private readonly outfitterRepository: IOutfitterRepository
  ) {
  }

  async execute (slug: string): Promise<void> {
    const outfitterResult = await this.contentfulGateway.getOutfitter(slug)
    if (outfitterResult !== undefined) {
      const outfitter = contentfulOutfitterToOutfitterEntity(outfitterResult)
      this.outfitterRepository.addOutfitter(outfitter)
    }
  }
}
