import { makeAutoObservable } from 'mobx'
import AdEntity from './ad.entity'

export default class AdsStore {
  list: AdEntity[] = []

  constructor () {
    makeAutoObservable(this)
  }
}
