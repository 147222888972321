import React from 'react'
import HeroImage from './HeroImage.jpg'
import './homepage-hero.css'
import SlamButton from '../button/slam-button.component'
import FormComponent from '../form/form.component'

interface HomepageHeroProps {
  searchInput: string
  changeInput: (text: string) => void
  onSubmit: () => void
}

const HomepageHero: React.FC<HomepageHeroProps> = (props: HomepageHeroProps) => {
  return (
    <section className='homepage-hero-container'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='hero-copy-container'>
              <div className='hero-copy'>
                <h1>Grand Slam Species Database</h1>
                <p className='hero-subtitle'>
                  At half-past eight the door opened, the policeman appeared, and,
                  requesting them to follow him, led the way to an adjoining hall.
                </p>

                <FormComponent onSearch={props.onSubmit}>
                  <div className='input-group mb-3'>
                    <input
                      type='text'
                      value={props.searchInput}
                      className='form-control hero-search-input'
                      placeholder='Search for species'
                      aria-label='Search for species'
                      aria-describedby='basic-addon2'
                      onChange={(e) => {
                        props.changeInput(e.currentTarget.value)
                      }}
                    />
                    <div className='input-group-append'>
                      <SlamButton type='submit' color='yellow'>Search</SlamButton>
                    </div>
                  </div>
                </FormComponent>

              </div>
            </div>
          </div>
          <div className='col-md-6 img-fluid'>
            <img src={HeroImage} alt='Slamquest' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageHero
