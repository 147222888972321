import React from 'react'
import './ad.css'
interface AdProps {
  type: 'horizontal-small' | 'horizontal-large' | 'vertical' | 'square'
  url?: string
  image?: any
}

const Ad: React.FC<AdProps> = (props: AdProps) => {
  if (props.image !== undefined && props.image.length > 0) {
    return (
      <div className={`ad-container ad-container-${props.type}`}>
        <a href={props.url}>
          <img src={props.image} className={`ad-image ad-image-${props.type}`} alt='' />
        </a>
      </div>
    )
  } else {
    return <div />
  }
}

export default Ad
