import React from 'react'
import './outfitter-card-horizontal.css'

interface OutfitterCardHorizontalProps {
  id: string
  slug: string
  title: string
  subTitle?: string
  image?: any
  location?: string
}

const OutfitterCardHorizontal: React.FC<OutfitterCardHorizontalProps> = (props: OutfitterCardHorizontalProps) => {
  return (
    <div className='outfitter-card-horizontal-container'>
      <div className='row'>
        <div className='col-md-3'>
          <div className='outfitter-card-horizontal-left-container'>
            <a href={`/outfitters/${props.slug}`}><img src={props.image} className='img-fluid outfitter-card-horizontal-image' alt={props.title} /></a>
          </div>
        </div>
        <div className='col-md-9'>
          <div className='outfitter-card-horizontal-right-container'>
            <a href={`/outfitters/${props.slug}`}>
              <div className='outfitter-card-horizontal-title'>{props.title}</div>
            </a>
            <div className='outfitter-card-horizontal-location'>{props.location}</div>
            <div className='outfitter-card-horizontal-subtitle'>{props.subTitle}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OutfitterCardHorizontal
