import { IStore } from '../../entities'
import ContentfulGateway from '../gateways/ContentfulGateway'
import AnimalRepository from '../gateways/animal.repository'
import LoadSingleAnimalCase from '../../usecases/animal/load-single-animal/load-single-animal.case'

export default class AnimalController {
  private readonly loadAnimalUseCase: LoadSingleAnimalCase

  constructor (store: IStore) {
    const contentfulGateway = new ContentfulGateway()
    const animalRepository = new AnimalRepository(store)
    this.loadAnimalUseCase = new LoadSingleAnimalCase(contentfulGateway, animalRepository)
  }

  async loadAnimal (slug: string): Promise<void> {
    return this.loadAnimalUseCase.execute(slug)
  }
}
