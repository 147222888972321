import OutfitterEntity from '../../../entities/outfitter.entity'
import { IOutfitterRepository } from '../../../interfaces/gateways/outfitter.repository'

export default class ViewFeaturedOutfittersCase {
  constructor (
    private readonly outfitterRepository: IOutfitterRepository
  ) {
  }

  execute (): OutfitterEntity[] {
    return this.outfitterRepository.getFeaturedOutfitters()
  }
}
