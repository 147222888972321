import { IStore } from '../../entities'
import ContentfulGateway from '../gateways/ContentfulGateway'
import LoadOutfittersCase from '../../usecases/outfitters/load-outfitters/load-outfitters.case'
import OutfitterRepository from '../gateways/outfitter.repository'
import SetSearchTermCase from '../../usecases/outfitters/set-search-term/set-search-term.case'
import LoadSingleOutfitterCase from '../../usecases/outfitters/load-single-outfitter/load-single-outfitter.case'
import LoadFeaturedOutfittersCase
  from '../../usecases/outfitters/load-featured-outfitters/load-featured-outfitters.case'
import SetOutfitterPageCase from '../../usecases/outfitters/set-outfitter-page/set-outfitter-page.case'

export default class OutfitterController {
  private readonly loadOutfittersCase: LoadOutfittersCase
  private readonly setSearchTerm: SetSearchTermCase
  private readonly loadSingleOutfitterCase: LoadSingleOutfitterCase
  private readonly loadFeaturedOutfittersCase: LoadFeaturedOutfittersCase
  private readonly setOutfitterPageCase: SetOutfitterPageCase

  constructor (store: IStore) {
    const contentfulGateway = new ContentfulGateway()
    const outfitterRepository = new OutfitterRepository(store)
    this.loadOutfittersCase = new LoadOutfittersCase(contentfulGateway, outfitterRepository)
    this.setSearchTerm = new SetSearchTermCase(outfitterRepository)
    this.loadSingleOutfitterCase = new LoadSingleOutfitterCase(contentfulGateway, outfitterRepository)
    this.loadFeaturedOutfittersCase = new LoadFeaturedOutfittersCase(contentfulGateway, outfitterRepository)
    this.setOutfitterPageCase = new SetOutfitterPageCase(outfitterRepository)
  }

  async loadOutfitters (animalId?: string): Promise<void> {
    return this.loadOutfittersCase.execute(animalId)
  }

  async loadOutfitter (slug: string): Promise<void> {
    return this.loadSingleOutfitterCase.execute(slug)
  }

  setSearch (term: string): void {
    this.setSearchTerm.execute(term)
  }

  async loadFeaturedOutfitters (): Promise<void> {
    return this.loadFeaturedOutfittersCase.execute()
  }

  async search (): Promise<void> {
    return this.loadOutfittersCase.execute()
  }

  setOutfitterPage (page: number): void {
    this.setOutfitterPageCase.execute(page)
  }
}
