import { IStore } from '../../entities'
import ContentfulGateway from '../gateways/ContentfulGateway'
import AnimalRepository from '../gateways/animal.repository'
import ViewAnimalsCase from '../../usecases/search/view-animal/view-animals.case'
import SetSearchTerm from '../../usecases/search/set-search-term/set-search-term.case'
import SearchAnimalsByNameCase from '../../usecases/search/search-animals-by-name/search-animals-by-name.case'
import SetSearchParams from '../../usecases/search/set-search-params/set-search-params.case'
import ToggleCategory from '../../usecases/search/select-category/toggle-category.case'
import ToggleContinent from '../../usecases/search/toggle-continent/toggle-continent.case'
import SetWeightFilter from '../../usecases/search/set-start-weight-filter/set-weight-filter.case'
import SetSearchPageCase from '../../usecases/search/set-search-page/set-search-page.case'

export default class SearchController {
  private readonly viewAnimalsCase: ViewAnimalsCase
  private readonly setSearchTerm: SetSearchTerm
  private readonly searchAnimalsByName: SearchAnimalsByNameCase
  private readonly setSearchParamsCase: SetSearchParams
  private readonly toggleCategoryCase: ToggleCategory
  private readonly toggleContinentCase: ToggleContinent
  private readonly setWeightCase: SetWeightFilter
  private readonly setSearchPageCase: SetSearchPageCase

  constructor (store: IStore) {
    const animalRepository = new AnimalRepository(store)
    const contentfulGateway = new ContentfulGateway()
    this.viewAnimalsCase = new ViewAnimalsCase(contentfulGateway, animalRepository)
    this.setSearchTerm = new SetSearchTerm(animalRepository)
    this.searchAnimalsByName = new SearchAnimalsByNameCase(contentfulGateway, animalRepository)
    this.setSearchParamsCase = new SetSearchParams(animalRepository)
    this.toggleCategoryCase = new ToggleCategory(animalRepository)
    this.toggleContinentCase = new ToggleContinent(animalRepository)
    this.setWeightCase = new SetWeightFilter(animalRepository)
    this.setSearchPageCase = new SetSearchPageCase(animalRepository)
  }

  async loadAnimals (): Promise<void> {
    return this.viewAnimalsCase.execute()
  }

  weightChange (weight: number[]): void {
    if (weight.length > 1) {
      this.setWeightCase.execute({ start: weight[0], end: weight[1] })
    }
  }

  setSearch (term: string): void {
    console.log(this.setSearchTerm)
    this.setSearchTerm.execute(term)
  }

  async search (): Promise<void> {
    return this.searchAnimalsByName.execute()
  }

  setSearchParameters (params: ISearchParams): void {
    this.setSearchParamsCase.execute(params)
  }

  toggleCategory (category: string): void {
    this.toggleCategoryCase.execute(category)
  }

  toggleContinent (continent: string): void {
    this.toggleContinentCase.execute(continent)
  }

  handlePageChange (page: number): void {
    this.setSearchPageCase.execute(page)
  }
}

interface ISearchParams {
  category?: string[]
  continent?: string
  weightRangeStart?: number
  weightRangeEnd?: number
}
