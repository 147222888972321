import React from 'react'

import { Container } from 'react-bootstrap'
import OutfitterCard from '../outfitter-card/outfitter-card.component'
import ContentHeader from '../content-header/content-header.component'
import { IOutfitterViewModel } from '../../../interfaces/presenters/outfitter.presenter'

interface FeaturedOutfittersProps {
  size: 'large' | 'small'
  outfitters: IOutfitterViewModel[]
}

const FeaturedOutfitters: React.FC<FeaturedOutfittersProps> = (props) => (
  <section>
    <Container>
      <ContentHeader
        title='Featured Outfitters'
        subTitle='At half-past eight the door opened, the policeman appeared, and, requesting them to follow him, led the way to an adjoining hall. '
      />
      <div className='row'>
        {props.outfitters.map((outfitter) => (
          <div key={outfitter.slug} className='col-md-4'>
            <OutfitterCard
              title={outfitter.title}
              image={outfitter.image}
              subTitle={outfitter.subTitle}
              slug={outfitter.slug}
            />
          </div>
        ))}
      </div>
    </Container>
  </section>
)

export default FeaturedOutfitters
