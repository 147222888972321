import React from 'react'
import Header from '../components/header/header.component'
import Footer from '../components/footer/footer.component'
import SingleAnimalContainer from '../components/single-animal/single-animal-container'
import FeaturedOutfittersContainer from '../components/featured-outfitters/featured-outfitters.container'

interface SingleAnimalScreenProps {
  title?: string
}

const SingleAnimalScreen: React.FC<SingleAnimalScreenProps> = () => (
  <div>
    <div>
      <Header />
      <SingleAnimalContainer />
      <FeaturedOutfittersContainer size='small' />
      <Footer />
    </div>
  </div>

)

export default SingleAnimalScreen
