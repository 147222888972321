import { IStore } from '../../entities'
import ContentfulGateway from '../gateways/ContentfulGateway'
import DownloadAdsCase from '../../usecases/ads/download-ads/download-ads.case'
import AdRepository from '../gateways/ad.repository'

export default class AdController {
  private readonly downloadAdsCase: DownloadAdsCase

  constructor (store: IStore) {
    const contentfulGateway = new ContentfulGateway()
    const adRepository = new AdRepository(store)
    this.downloadAdsCase = new DownloadAdsCase(contentfulGateway, adRepository)
  }

  async loadAds (): Promise<void> {
    return this.downloadAdsCase.execute()
  }
}
