import React from 'react'
import TodoListStore from './TodoListStore'
import TodoFormStore from './TodoFormStore'
import CategoryStore from './category.store'
import AnimalStore from './animal.store'
import AdsStore from './ads.store'
import OutfitterStore from './outfitter.store'

export type IStore = ReturnType<typeof getStore>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function getStore () {
  return {
    todoList: new TodoListStore(),
    todoForm: new TodoFormStore(),
    categories: new CategoryStore(),
    animals: new AnimalStore(),
    ads: new AdsStore(),
    outfitters: new OutfitterStore()
  }
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const StoreContext = React.createContext({} as IStore)
