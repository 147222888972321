import { IStore } from '../../entities'
import ViewOutfittersCase from '../../usecases/outfitters/view-outfitters/view-outfitters.case'
import OutfitterRepository from '../gateways/outfitter.repository'
import ViewSingleOutfitterCase from '../../usecases/outfitters/view-single-outfitter/view-single-outfitter.case'
import ViewFeaturedOutfittersCase
  from '../../usecases/outfitters/view-featured-outfitters/view-featured-outfitters.case'
import OutfitterEntity from '../../entities/outfitter.entity'

export interface IOutfitterViewModel {
  id: string
  slug: string
  title: string
  subTitle?: string
  details: string
  contacts: string
  image?: any
  location: string
  phone: string
  website: string
  email: string
  species: Array<{name: string, slug: string}>
}

export default class OutfitterPresenter {
  private readonly store: IStore
  private readonly viewOutfittersCase: ViewOutfittersCase
  private readonly viewSingleOutfitterCase: ViewSingleOutfitterCase
  private readonly viewFeaturedOutfittersCase: ViewFeaturedOutfittersCase

  constructor (store: IStore) {
    this.store = store
    const outfitterRepo = new OutfitterRepository(store)
    this.viewOutfittersCase = new ViewOutfittersCase(outfitterRepo)
    this.viewSingleOutfitterCase = new ViewSingleOutfitterCase(outfitterRepo)
    this.viewFeaturedOutfittersCase = new ViewFeaturedOutfittersCase(outfitterRepo)
  }

  getOutfitters (): IOutfitterViewModel[] {
    const outfitterEntities = this.viewOutfittersCase.execute()
    return this.outfitterEntitiesToViewModels(outfitterEntities)
  }

  getPage (): number {
    return this.store.outfitters.currentPage + 1
  }

  getTotal (): number {
    return this.store.outfitters.totalResults
  }

  getSearchTerm (): string {
    return this.store.outfitters.searchTerm
  }

  getFeaturedOutfitters (): IOutfitterViewModel[] {
    const outfitterEntities = this.viewFeaturedOutfittersCase.execute()
    return this.outfitterEntitiesToViewModels(outfitterEntities)
  }

  outfitterEntitiesToViewModels (outfitterEntities: OutfitterEntity[]): IOutfitterViewModel[] {
    const outfitterViewModels: IOutfitterViewModel[] = []
    outfitterEntities.forEach((outfitterEnt) => {
      outfitterViewModels.push({
        id: outfitterEnt.id,
        slug: outfitterEnt.slug,
        subTitle: outfitterEnt.description?.substr(0, 200),
        title: outfitterEnt.name,
        image: outfitterEnt.image,
        location: outfitterEnt.location,
        details: outfitterEnt.description,
        contacts: outfitterEnt.contact,
        phone: outfitterEnt.phone?.toString(),
        website: outfitterEnt.website,
        email: outfitterEnt.email,
        species: outfitterEnt.species
      })
    })
    return outfitterViewModels
  }

  getOutfitter (slug: string): IOutfitterViewModel {
    const outfitterEnt = this.viewSingleOutfitterCase.execute(slug)
    return {
      id: outfitterEnt?.id ?? '',
      slug: outfitterEnt?.slug ?? '',
      title: outfitterEnt?.name ?? '',
      subTitle: outfitterEnt?.description ?? '',
      image: outfitterEnt?.image ?? '',
      location: outfitterEnt?.location ?? '',
      details: outfitterEnt?.description ?? '',
      contacts: outfitterEnt?.contact ?? '',
      phone: outfitterEnt?.phone?.toString() ?? '',
      website: outfitterEnt?.website ?? '',
      email: outfitterEnt?.email ?? '',
      species: outfitterEnt?.species ?? []
    }
  }
}
