import { makeAutoObservable } from 'mobx'
import OutfitterEntity from './outfitter.entity'

export default class OutfitterStore {
  loading: boolean = false
  results: OutfitterEntity[] = []
  featuredOutfitters: OutfitterEntity[] = []
  totalResults: number = 0
  currentPage: number = 0
  searchTerm: string = ''
  outfitterMap: Map<string, OutfitterEntity> = new Map()

  constructor () {
    makeAutoObservable(this)
  }
}
