import { IStore } from '../../entities'
import AnimalEntity from '../../entities/animal.entity'

export interface IAnimalRepository {
  getPage: () => number
  clearResults: () => void
  addAnimalsToResults: (animals: AnimalEntity[]) => void
  setPage: (page: number) => void
  setTotal: (total: number) => void
  saveAnimal: (slug: string, animal: AnimalEntity) => void
  getAnimal: (slug: string) => AnimalEntity | undefined
  setSearchTerm: (searchTerm: string) => void
  getSearchTerm: () => string
  toggleCategory: (category: string) => void
  toggleContinent: (continent: string) => void
  setLoading: (isLoading: boolean) => void
  setSearchParams: (params: {
    category?: string[]
    continents?: string[]
    startWeight?: number
    endWeight?: number
  }) => void
  getSearchParams: () => {
    categories: string[]
    continents: string[]
    startWeight: number
    endWeight: number
  }
}

export default class AnimalRepository implements IAnimalRepository {
  private readonly store: IStore

  constructor (store: IStore) {
    this.store = store
  }

  saveAnimal (slug: string, animal: AnimalEntity): void {
    this.store.animals.animalMap.set(slug, animal)
  }

  getAnimal (slug: string): AnimalEntity | undefined {
    return this.store.animals.animalMap.get(slug)
  }

  addAnimalsToResults (animals: AnimalEntity[]): void {
    this.store.animals.results = animals
  }

  clearResults (): void {
    this.store.animals.results = []
  }

  setPage (page: number): void {
    this.store.animals.currentPage = page
  }

  setTotal (total: number): void {
    this.store.animals.totalResults = total
  }

  getPage (): number {
    return this.store.animals.currentPage
  }

  setSearchTerm (searchTerm: string): void {
    this.store.animals.searchTerm = searchTerm
  }

  getSearchTerm (): string {
    return this.store.animals.searchTerm
  }

  setSearchParams (params: {
    category?: string[]
    continents?: string[]
    startWeight?: number
    endWeight?: number
  }): void {
    if (params.category !== undefined) { this.store.animals.searchParams.categories = params.category }
    if (params.continents !== undefined) { this.store.animals.searchParams.continents = params.continents }
    if (params.startWeight !== undefined) { this.store.animals.searchParams.startWeight = params.startWeight }
    if (params.endWeight !== undefined) { this.store.animals.searchParams.endWeight = params.endWeight }
  }

  getSearchParams (): {
    categories: string[]
    continents: string[]
    startWeight: number
    endWeight: number
  } {
    return this.store.animals.searchParams
  }

  toggleCategory (category: string): void {
    this.toggleSearchParameter(category, 'categories')
  }

  toggleSearchParameter (itemId: string, type: 'categories' | 'continents'): void {
    const searchFilterArray = this.store.animals.searchParams[type]
    if (searchFilterArray.includes(itemId)) {
      this.store.animals.searchParams[type] = searchFilterArray.filter((item) => item !== itemId)
    } else {
      searchFilterArray.push(itemId)
    }
  }

  toggleContinent (continent: string): void {
    this.toggleSearchParameter(continent, 'continents')
  }

  setLoading (isLoading: boolean): void {
    this.store.animals.loading = isLoading
  }
}
