import AdEntity from '../../../entities/ad.entity'
import { IAdRepository } from '../../../interfaces/gateways/ad.repository'
import _ from 'lodash'

export default class ViewSingleAdCase {
  constructor (
    private readonly adRepository: IAdRepository
  ) {
  }

  execute (type: 'horizontal-small' | 'horizontal-large' | 'vertical' | 'square'): AdEntity | undefined {
    const filteredAds = this.adRepository.getAdsWithSize(adSizeMap[type])
    return _.sample(filteredAds)
  }
}

const adSizeMap = {
  'horizontal-small': '728x90',
  'horizontal-large': '930x180',
  square: '250x250',
  vertical: '300x600'
}
