import React from 'react'
import './outfitter-filter.css'
import SlamButton from '../button/slam-button.component'
import FormComponent from '../form/form.component'

interface OutfitterFilterProps {
  searchTerm: string
  onSearch: () => void
  onSearchTextUpdate: (text: string) => void
}

const OutfitterFilter: React.FC<OutfitterFilterProps> = (props: OutfitterFilterProps) => {
  return (
    <div className='search-filter-container'>
      <h3 className='search-filter-sidebar-title'>Filter</h3>

      <FormComponent onSearch={props.onSearch}>
        <div className='input-group mb-3'>
          <input
            type='text'
            className='form-control hero-search-input'
            placeholder='Search for outfitters'
            aria-label='Search for outfitters'
            aria-describedby='basic-addon2'
            value={props.searchTerm}
            onChange={(e) => {
              props.onSearchTextUpdate(e.currentTarget.value)
            }}
          />
          <div className='input-group-append'>
            <SlamButton type='submit' color='yellow'>Search</SlamButton>
          </div>
        </div>
      </FormComponent>
    </div>
  )
}

export default OutfitterFilter
