import { IAnimalRepository } from '../../../interfaces/gateways/animal.repository'

export default class SetSearchParams {
  constructor (
    private readonly animalRepository: IAnimalRepository
  ) {
  }

  execute (params: {
    category?: string[]
    continents?: string[]
    startWeight?: number
    endWeight?: number
  }): void {
    this.animalRepository.setSearchParams(params)
  }
}
