import { IAnimalRepository } from '../../../interfaces/gateways/animal.repository'
import { IContentfulGateway } from '../../../interfaces/gateways/ContentfulGateway'
import { contentfulAnimalToAnimalEntity } from '../../../entities/transformers'

export default class LoadSingleAnimalCase {
  constructor (
    private readonly contentfulGateway: IContentfulGateway,
    private readonly animalRepository: IAnimalRepository
  ) {
  }

  async execute (slug: string): Promise<void> {
    const animal = await this.contentfulGateway.getAnimal(slug)
    if (animal !== undefined) {
      const animalEntity = contentfulAnimalToAnimalEntity(animal)
      this.animalRepository.saveAnimal(slug, animalEntity)
    }
  }
}
