import { IAnimalRepository } from '../../../interfaces/gateways/animal.repository'

export default class SetSearchPageCase {
  constructor (
    private readonly animalRepository: IAnimalRepository
  ) {
  }

  execute (page: number): void {
    this.animalRepository.setPage(page)
  }
}
