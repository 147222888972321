import { IContentfulGateway } from '../../../interfaces/gateways/ContentfulGateway'
import { ICategoryRepository } from '../../../interfaces/gateways/CategoriesRepository'
import CategoryEntity from '../../../entities/category.entity'

export default class ViewCategoriesCase {
  constructor (
    public categoriesRepository: ICategoryRepository,
    public contentfulGateway: IContentfulGateway
  ) {
  }

  async execute (): Promise<any> {
    const categories = await this.contentfulGateway.getCategories()
    const categoryEntities: CategoryEntity[] = []
    categories.forEach((cat) => {
      const entity: CategoryEntity = new CategoryEntity()
      entity.id = cat.sys.id
      entity.name = cat.fields.title
      entity.slug = cat.fields.slug
      entity.image = cat.fields.image.fields.file.url
      categoryEntities.push(entity)
    })
    this.categoriesRepository.addCategories(categoryEntities)
  }
}
