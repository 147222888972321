import React, { useState } from 'react'
import './outfitter-card.css'

interface OutfitterCardProps {
  title: string
  subTitle?: string
  image?: any
  alt?: string
  slug: string
}

const OutfitterCard: React.FC<OutfitterCardProps> = (props: OutfitterCardProps) => {
  const [isHovered, setHovered] = useState(false)

  const onHover = (): void => {
    if (!isHovered) {
      setHovered(true)
    }
  }

  const onHoverExit = (): void => {
    if (isHovered) {
      setHovered(false)
    }
  }

  if (!isHovered) {
    return (
      <a href={`/outfitters/${props.slug}`}>
        <div onMouseEnter={onHover} onMouseLeave={onHoverExit} className='outfitter-card-container'>
          <img className='outfitter-card-image' src={props.image} alt={props.alt} />
        </div>
      </a>
    )
  }

  return (
    <a href={`/outfitters/${props.slug}`}>
      <div onMouseEnter={onHover} onMouseLeave={onHoverExit} className='outfitter-card-container'>
        <div className='outfitter-card-description'>
          <div className='outfitter-card-title'>{props.title}</div>
          <div className='outfitter-card-subtitle'>{props.subTitle}</div>
        </div>
      </div>
    </a>
  )
}

export default OutfitterCard
