import { IContentfulGateway } from '../../../interfaces/gateways/ContentfulGateway'
import { IOutfitterRepository } from '../../../interfaces/gateways/outfitter.repository'
import OutfitterEntity from '../../../entities/outfitter.entity'
import { contentfulOutfitterToOutfitterEntity } from '../../../entities/transformers'

export default class LoadOutfittersCase {
  constructor (
    private readonly contentfulGateway: IContentfulGateway,
    private readonly outfitterRepository: IOutfitterRepository
  ) {
  }

  async execute (animalId?: string): Promise<void> {
    this.outfitterRepository.setLoading(true)
    const searchTerm = this.outfitterRepository.getSearchTerm()
    const page = this.outfitterRepository.getPage()
    const outfittersAPIResult = await this.contentfulGateway.searchOutfitters(searchTerm, page, animalId)
    this.outfitterRepository.setOutfitterTotal(outfittersAPIResult.total)
    const outfitters: OutfitterEntity[] = []
    outfittersAPIResult.items.forEach((outfitterAPIItem) => {
      const outfitter = contentfulOutfitterToOutfitterEntity(outfitterAPIItem)
      outfitters.push(outfitter)
    })
    this.outfitterRepository.setOutfitterResults(outfitters)
    this.outfitterRepository.setLoading(false)
  }
}
