import AnimalEntity from './animal.entity'
import { IContentfulAnimal, IContentfulOutfitter } from '../interfaces/gateways/ContentfulGateway'
import OutfitterEntity from './outfitter.entity'

export function contentfulAnimalsToAnimalEntities (contentfulAnimals: IContentfulAnimal[]): AnimalEntity[] {
  const animalEntities: AnimalEntity[] = []
  contentfulAnimals.forEach((animal) => {
    const aEntity: AnimalEntity = contentfulAnimalToAnimalEntity(animal)
    animalEntities.push(aEntity)
  })
  return animalEntities
}

export function contentfulAnimalToAnimalEntity (animal: IContentfulAnimal): AnimalEntity {
  return {
    id: animal.sys.id,
    slug: animal.fields.slug,
    name: animal.fields.name,
    behavior: animal.fields.behavior,
    location: animal.fields.location,
    locationDescription: animal.fields.locationDescription,
    scientificName: animal.fields.scientificName,
    summary: animal.fields.summary,
    images: animal.fields.images.map((animal) => animal.fields.file.url),
    mapImage: animal.fields.mapImage?.fields.file.url
  }
}

export function contentfulOutfitterToOutfitterEntity (outfitterAPIItem: IContentfulOutfitter): OutfitterEntity {
  const outfitter = new OutfitterEntity()
  outfitter.contact = outfitterAPIItem.fields.contact
  outfitter.email = outfitterAPIItem.fields.email
  outfitter.fax = outfitterAPIItem.fields.fax
  outfitter.id = outfitterAPIItem.sys.id
  outfitter.slug = outfitterAPIItem.fields.slug
  outfitter.image = outfitterAPIItem.fields.image?.fields.file.url
  outfitter.location = outfitterAPIItem.fields.location
  outfitter.phone = outfitterAPIItem.fields.phoneNumber
  outfitter.name = outfitterAPIItem.fields.name
  outfitter.website = outfitterAPIItem.fields.website
  outfitter.description = outfitterAPIItem.fields.description
  outfitter.species = []
  outfitterAPIItem.fields.species?.forEach((species) => {
    outfitter.species.push({
      slug: species.fields.slug,
      name: species.fields.name
    })
  })
  return outfitter
}
