import React from 'react'
import { BUTTON_STYLE } from './slam-button.component'

interface SlamButtonProps {
  href: string
  color?: 'yellow' | 'blue'
  onClick?: () => void
  children?: any
  style?: any
  type?: 'button' | 'submit' | 'reset'
}

const SlamLink: React.FC<SlamButtonProps> = (props: SlamButtonProps) => {
  return (
    <a
      href={props.href}
      onClick={props.onClick}
      className='btn'
      style={BUTTON_STYLE(props)}
    >{props.children}
    </a>
  )
}

export default SlamLink
