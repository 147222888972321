import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './single-animal.css'
import { ISingleAnimalViewModel } from '../../../interfaces/presenters/animal.presenter'
import AdContainer from '../ad/ad.container'
import SlamLink from '../button/slam-link.component'

const SingleAnimal: React.FC<ISingleAnimalViewModel> = (props: ISingleAnimalViewModel) => {
  return (
    <section className='animal-section-container'>
      <Container>
        <Row>
          <Col md={6}>
            <Carousel
              showStatus={props.isSingleImage}
              showThumbs={props.isSingleImage}
              showIndicators={props.isSingleImage}
              thumbWidth={100}
            >
              {props.images.map((image, index) => (
                <div key={`animal-image-${index}`}>
                  <img src={image} alt='' />
                </div>
              )) as any}
            </Carousel>
            <img className='img-fluid animal-section-map' src={props.locationImage} alt='' />
          </Col>
          <Col md={6}>
            <h1 className='animal-section-title'>{props.animalName}</h1>
            <div className='animal-location'>{props.scientificName}</div>
            <b>LOCATION:</b>
            <div className='animal-location-description'>{props.locationDescription}</div>
            <b>ANIMAL SUMMARY:</b>
            <div className='animal-summary'>{props.animalSummary}</div>
            <Row>
              <Col md={6}><SlamLink href={`/outfitters?animalId=${props.animalId}`} color='blue'>Find an Outfitter</SlamLink> </Col>
              <Col md={6}><SlamLink href='https://www.slamquest.org/grand-slam-trophy-submission.php' color='blue' style={{ paddingLeft: '20px', paddingRight: '20px' }}>Register Your Trophy</SlamLink> </Col>
            </Row>
            <Row>
              <Col md={6}><AdContainer type='square' /></Col>
              <Col md={6}><AdContainer type='square' /></Col>
            </Row>
            <section>
              <div className='animal-disclaimer'>
                Super Ten®/Super Slam®: Information found here contains excerpts from the on-line and printed
                version of Safari Club International (SCI) Record Book of Trophy Animals and is used by
                permission. Visit <a href='https://www.scirecordbook.org'>www.scirecordbook.org</a>.
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      <section className='animal-footer-ad-container'>
        <AdContainer type='horizontal-large' />
      </section>
    </section>
  )
}

export default SingleAnimal
