import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../entities'
import { Observer } from 'mobx-react-lite'
import SearchPresenter from '../../../interfaces/presenters/search.presenter'
import SearchController from '../../../interfaces/controllers/search.controller'
import SearchFilter from './search-filter.component'
import { useLocation } from 'react-router-dom'

const SearchFilterContainer: React.FC<any> = () => {
  const store = useContext(StoreContext)
  const presenter = new SearchPresenter(store)
  const controller = new SearchController(store)

  const useQuery = (): URLSearchParams => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()

  const searchParams = {
    category: [query.get('category') as string]
  }

  useEffect(() => {
    controller.setSearchParameters(searchParams)
    controller.loadAnimals()
  })

  const handleSearch = async (): Promise<void> => {
    await controller.search()
  }

  const handleSearchInput = (text: string): void => {
    controller.setSearch(text)
  }

  const handleToggleFilter = async (filter: string, type: 'category' | 'continent'): Promise<void> => {
    if (type === 'category') {
      controller.toggleCategory(filter)
    }
    if (type === 'continent') {
      controller.toggleContinent(filter)
    }
    controller.handlePageChange(0)
    await controller.loadAnimals()
  }

  const handleToggleCategory = async (category: string): Promise<void> => {
    return handleToggleFilter(category, 'category')
  }

  const handleToggleContinent = async (category: string): Promise<void> => {
    return handleToggleFilter(category, 'continent')
  }

  return (
    <Observer>
      {() => (
        <>
          <div style={{ height: 0, width: 0, overflow: 'hidden' }}>{presenter.getSearchCategories()} {presenter.getContinents()}</div>
          <SearchFilter
            onToggleCategory={handleToggleCategory}
            categories={presenter.getSearchCategories()}
            continents={presenter.getContinents()}
            searchTerm={presenter.getSearchTerm()}
            onSearch={handleSearch}
            onToggleContinent={handleToggleContinent}
            onSearchTextUpdate={handleSearchInput}
          />
        </>
      )}
    </Observer>
  )
}

export default SearchFilterContainer
