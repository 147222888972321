import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../entities'
import { Observer } from 'mobx-react-lite'
import OutfitterResults from './outfitter-results.component'
import OutfitterController from '../../../interfaces/controllers/outfitter.controller'
import OutfitterPresenter from '../../../interfaces/presenters/outfitter.presenter'
import { useLocation } from 'react-router-dom'

const OutfitterResultsContainer: React.FC<any> = () => {
  const store = useContext(StoreContext)
  const controller = new OutfitterController(store)
  const presenter = new OutfitterPresenter(store)
  const useQuery = (): URLSearchParams => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()

  const loadOutfitters = (): void => {
    controller.loadOutfitters(query.get('animalId') ?? undefined)
  }
  useEffect(() => {
    loadOutfitters()
  })

  const handlePageChange = (page: number): void => {
    controller.setOutfitterPage(page - 1)
    loadOutfitters()
  }

  return (
    <>
      <Observer>
        {() => (
          <OutfitterResults
            onPageChanged={handlePageChange}
            totalPages={presenter.getTotal()}
            currentPage={presenter.getPage()}
            outfitters={presenter.getOutfitters()}
          />
        )}
      </Observer>
    </>
  )
}

export default OutfitterResultsContainer
