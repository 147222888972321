import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../entities'
import { Observer } from 'mobx-react-lite'
import Ad from './ad.component'
import AdPresenter from '../../../interfaces/presenters/ad.presenter'
import AdController from '../../../interfaces/controllers/ad.controller'

interface AdContainerProps {
  type: 'horizontal-small' | 'horizontal-large' | 'vertical' | 'square'
}

const AdContainer: React.FC<AdContainerProps> = (props: AdContainerProps) => {
  const store = useContext(StoreContext)
  const presenter = new AdPresenter(store)
  const controller = new AdController(store)

  useEffect(() => {
    controller.loadAds()
  })

  return (
    <Observer>
      {() => {
        const ad = presenter.getAd(props.type)
        return (
          <Ad type={props.type} url={ad.url} image={ad.image} />
        )
      }}
    </Observer>
  )
}

export default AdContainer
