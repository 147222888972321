import { IStore } from '../../entities'

interface IAnimalResultViewModel {
  image: string
  name: string
  slug: string
  id: string
}

export default class SearchPresenter {
  private readonly store: IStore

  constructor (store: IStore) {
    this.store = store
  }

  getCurrentPage (): number {
    return this.store.animals.currentPage + 1
  }

  getTotalResults (): number {
    return this.store.animals.totalResults
  }

  getLoaded (): boolean {
    return this.store.animals.loading
  }

  getSpecies (): string {
    const categories: string[] = []
    this.store.animals.searchParams.categories.forEach((category) => {
      if (category !== undefined && category !== null) {
        categories.push(category.replaceAll('-', ' '))
      }
    })
    return categories.join(', ')
  }

  getResults (): IAnimalResultViewModel[] {
    const animalResults: IAnimalResultViewModel[] = []
    this.store.animals.results.forEach((animal) => {
      animalResults.push({
        id: animal.id,
        slug: animal.slug,
        name: animal.name,
        image: animal.images.length > 0 ? animal.images[0] : ''
      })
    })
    return animalResults
  }

  getSearchTerm (): string {
    return this.store.animals.searchTerm
  }

  getSearchCategories (): string[] {
    return this.store.animals.searchParams.categories
  }

  getContinents (): string[] {
    return this.store.animals.searchParams.continents
  }
}
