import { IOutfitterRepository } from '../../../interfaces/gateways/outfitter.repository'

export default class SetSearchTermCase {
  constructor (
    private readonly outfitterRepository: IOutfitterRepository
  ) {
  }

  execute (term: string): void {
    this.outfitterRepository.setSearchTerm(term)
  }
}
