import { makeAutoObservable } from 'mobx'

export default class AdEntity {
  id: string = ''
  name: string = ''
  size: string = ''
  url: string = ''
  image: string = ''

  constructor () {
    makeAutoObservable(this)
  }
}
