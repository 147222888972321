import React from 'react'
import Header from '../components/header/header.component'
import Footer from '../components/footer/footer.component'
import OutfitterDetailsContainer from '../components/outfitter-details/outfitter-details.container'
import AdContainer from '../components/ad/ad.container'

interface OutfitterSingleScreenProps {
  title?: string
}

const OutfitterSingleScreen: React.FC<OutfitterSingleScreenProps> = () => {
  return (
    <div>
      <div>
        <Header />
        <section />
        <div className='container'>
          <OutfitterDetailsContainer />
          <AdContainer type='horizontal-large' />
        </div>
        <Footer />
      </div>
    </div>

  )
}

export default OutfitterSingleScreen
