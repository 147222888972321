import { IAnimalRepository } from '../../../interfaces/gateways/animal.repository'

export default class SetWeightFilter {
  constructor (
    private readonly animalRepository: IAnimalRepository
  ) {
  }

  execute (weights: {start?: number, end?: number}): void {
    this.animalRepository.setSearchParams({ startWeight: weights.start, endWeight: weights.end })
  }
}
