import { makeAutoObservable } from 'mobx'

export default class TodoFormStore {
  constructor () {
    makeAutoObservable(this)
  }

  inputValue: string = ''

  updateValue (inputValue: string): void {
    this.inputValue = inputValue
  }
}
