import { IOutfitterRepository } from '../../../interfaces/gateways/outfitter.repository'
import OutfitterEntity from '../../../entities/outfitter.entity'

export default class ViewSingleOutfitterCase {
  constructor (
    private readonly outfitterRepository: IOutfitterRepository
  ) {
  }

  execute (slug: string): OutfitterEntity | undefined {
    return this.outfitterRepository.getOutfitter(slug)
  }
}
