import { IStore } from '../../entities'
import AdEntity from '../../entities/ad.entity'
import _ from 'lodash'

export interface IAdRepository {
  adsLoaded: () => boolean
  setAds: (ads: AdEntity[]) => void
  getAdsWithSize: (size: string) => AdEntity[]
}

export default class AdRepository implements IAdRepository {
  private readonly store: IStore

  constructor (store: IStore) {
    this.store = store
  }

  setAds (ads: AdEntity[]): void {
    this.store.ads.list = ads
  }

  getAdsWithSize (size: string): AdEntity[] {
    return _.filter(this.store.ads.list, (ad) => {
      return ad.size === size
    })
  }

  adsLoaded (): boolean {
    return this.store.ads.list.length > 0
  }
}
