import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../entities'
import CategoryPresenter from '../../../interfaces/presenters/category.presenter'
import { Observer } from 'mobx-react-lite'
import CategoryCard from './category-card.component'
import ContentHeader from '../content-header/content-header.component'
import CategoryController from '../../../interfaces/controllers/category.controller'

const CategoriesContainer: React.FC<any> = () => {
  const store = useContext(StoreContext)
  const presenter = new CategoryPresenter(store)
  const controller = new CategoryController(store)
  useEffect(() => {
    controller.loadCategories()
  })

  return (
    <>
      <ContentHeader
        title='Categories'
        subTitle='At half-past eight the door opened, the policeman appeared, and, requesting them to follow him, led the way to an adjoining hall. '
      />
      <Observer>
        {() => (
          <div className='container'>
            <div className='row'>
              {presenter.getCategories().map((category, index) => (
                <div key={`cat-${index}`} className='col-md-6'>
                  <a href={`/search/?category=${category.slug}`}><CategoryCard image={category.image} title={category.name} /></a>
                </div>
              ))}
            </div>
          </div>
        )}
      </Observer>
    </>
  )
}

export default CategoriesContainer
