import React from 'react'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './outfitter-results.css'
import AdContainer from '../ad/ad.container'
import OutfitterCardHorizontal from '../outfitter-card-horizontal/outfitter-card-horizontal.component'
import { PAGINATION_COUNT } from '../../../interfaces/gateways/ContentfulGateway'

interface OutfitterResultsProps {
  totalPages: number
  currentPage: number
  onPageChanged: (page: number) => void
  outfitters: Array<{
    id: string
    slug: string
    title: string
    subTitle?: string
    image?: any
    location?: string
  }>
}

const OutfitterResults: React.FC<OutfitterResultsProps> = (props: OutfitterResultsProps) => {
  const onPageChanged = (page: number): void => {
    props.onPageChanged(page)
  }

  return (
    <div className='container search-results-container'>
      <h3 className='search-result-title'>Outfitters</h3>
      <div className='container-fluid'>
        {props.outfitters.map((outfitter, index) => (
          <OutfitterCardHorizontal
            key={outfitter.id}
            id={outfitter.id}
            slug={outfitter.slug}
            title={outfitter.title}
            image={outfitter.image}
            subTitle={outfitter.subTitle}
            location={outfitter.location}
          />
        ))}
      </div>

      <AdContainer type='horizontal-small' />
      <Pagination
        pageSize={PAGINATION_COUNT}
        onChange={onPageChanged}
        showTotal={(total, range) =>
          `${range[0]} - ${range[1]} of ${total} items`}
        total={props.totalPages}
        current={props.currentPage}
      />

    </div>
  )
}

export default OutfitterResults
