import { IStore } from '../../entities'
import ViewSingleAnimal from '../../usecases/animal/view-single-animal/view-single-animal.case'
import AnimalRepository from '../gateways/animal.repository'
import AnimalEntity from '../../entities/animal.entity'

export interface ISingleAnimalViewModel {
  animalId: string
  animalName: string
  location: string
  scientificName: string
  locationDescription: string
  isSingleImage: boolean
  animalSummary: string
  images: string[]
  locationImage: string
}

export default class AnimalPresenter {
  private readonly store: IStore
  private readonly viewAnimalUseCase: ViewSingleAnimal

  constructor (store: IStore) {
    this.store = store
    this.viewAnimalUseCase = new ViewSingleAnimal(new AnimalRepository(store))
  }

  animalToViewModel (animal?: AnimalEntity): ISingleAnimalViewModel {
    return {
      animalId: animal?.id ?? '',
      animalName: animal?.name ?? '',
      location: animal?.location ?? '',
      locationDescription: animal?.locationDescription ?? '',
      animalSummary: animal?.summary ?? '',
      images: animal?.images ?? [],
      locationImage: animal?.mapImage ?? '',
      scientificName: animal?.scientificName ?? '',
      isSingleImage: animal !== undefined ? (animal.images.length > 1) : false
    }
  }

  getAnimal (slug: string): ISingleAnimalViewModel {
    const animal = this.viewAnimalUseCase.execute(slug)
    return this.animalToViewModel(animal)
  }
}
