import React from 'react'
import './form.css'
interface AdProps {
  onSearch: () => void
  children?: any
}

const FormComponent: React.FC<AdProps> = (props: AdProps) => {
  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      props.onSearch()
    }}
    >
      {props.children}
    </form>
  )
}

export default FormComponent
