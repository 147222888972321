import { makeAutoObservable } from 'mobx'
import { ITodoModel } from './TodoModel'

export interface ITodoListStore {
  todos: ITodoModel[]
  addTodo: (todo: ITodoModel) => void
  removeTodo: (id: number) => void
}

export default class TodoListStore implements ITodoListStore {
  constructor () {
    makeAutoObservable(this)
  }

  map = new Map<number, ITodoModel>()

  get todos (): ITodoModel[] {
    return Array.from(this.map.values())
  }

  addTodo (todo: ITodoModel): void {
    this.map.set(todo.id, todo)
  }

  removeTodo (id: number): void {
    this.map.delete(id)
  }
}
