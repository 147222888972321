import { makeAutoObservable } from 'mobx'

export default class OutfitterEntity {
  id: string = ''
  name: string = ''
  contact: string = ''
  location: string = ''
  fax: string = ''
  slug: string = ''
  email: string = ''
  phone: string[] = []
  website: string = ''
  description: string = ''
  image: string = ''
  species: Array<{
    name: string
    slug: string
  }> = []

  constructor () {
    makeAutoObservable(this)
  }
}
