import { IAnimalRepository } from '../../../interfaces/gateways/animal.repository'

export default class SetSearchTerm {
  constructor (
    private readonly animalRepository: IAnimalRepository
  ) {
  }

  execute (searchTerm: string): void {
    this.animalRepository.setSearchTerm(searchTerm)
  }
}
