import { IContentfulGateway } from '../../../interfaces/gateways/ContentfulGateway'
import { IAdRepository } from '../../../interfaces/gateways/ad.repository'
import AdEntity from '../../../entities/ad.entity'

export default class DownloadAdsCase {
  constructor (
    private readonly contentfulGateway: IContentfulGateway,
    private readonly adRepository: IAdRepository
  ) {
  }

  async execute (): Promise<void> {
    const adsLoaded = this.adRepository.adsLoaded()
    if (!adsLoaded) {
      const adEntities: AdEntity[] = []
      const adsModels = await this.contentfulGateway.getAllAds()
      adsModels.items.forEach((ad) => {
        const adEntity: AdEntity = new AdEntity()
        adEntity.id = ad.sys.id
        adEntity.name = ad.fields.name
        adEntity.size = ad.fields.size
        adEntity.url = ad.fields.url
        adEntity.image = ad.fields.image.fields.file.url
        adEntities.push(adEntity)
      })
      this.adRepository.setAds(adEntities)
    }
  }
}
